import React, { useEffect, useRef, useState } from 'react';

const TouchableOpacity = ({
	children,
	style,
	className="",
	onPress=() => {},
	onLongPress = null,
	activeOpacity = 0.2,
	hoverOpacity = 0.8,
	disabled = false,
	disabledOpacity = 0.4,
	delay = 300, // Time threshold for double-click detection
	onMouseDown = () => {},
	onMouseUp = () => {},
	onMouseEnter = () => {},
	onMouseLeave = () => {}
}) => {
	const [isPressed, setIsPressed] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const clickTimeout = useRef(null); // Ref to manage click delay

	const handleMouseDown = () => { setIsPressed(true); onMouseDown(); }; // Press state
	const handleMouseUp = () => { setIsPressed(false); onMouseUp(); }; // Release state
	const handleMouseEnter = () => { setIsHovered(true); onMouseEnter(); }; // Hover state
	const handleMouseLeave = () => {
		setIsHovered(false);
		setIsPressed(false);
		onMouseLeave();
	}; // Reset hover and press

	const mergedStyle = {
		border: "none",
		outline: "none",
		opacity: disabled
			? disabledOpacity
			: isPressed
				? activeOpacity
				: isHovered
					? hoverOpacity
					: 1, // Opacity based on state
		cursor: disabled ? 'not-allowed' : 'pointer', // Disable pointer interactions if disabled
		transition: 'opacity 0.2s', // Smooth transition
		...style
	};

	const handleClick = () => {
		if (disabled) return;

		if (onLongPress) {
			// Clear timeout if double-click occurs
			if (clickTimeout.current) {
				clearTimeout(clickTimeout.current);
				clickTimeout.current = null;
				return; // Do not trigger onPress
			}

			// Wait to confirm if it's a single or double click
			clickTimeout.current = setTimeout(() => {
				onPress();
				clickTimeout.current = null;
			}, delay);
		} else {
			onPress();
		}
	};

	const handleDoubleClick = () => {
		if (disabled) return;

		if (onLongPress) {
			clearTimeout(clickTimeout.current); // Cancel single click action
			clickTimeout.current = null;
			onLongPress(); // Trigger double-click as onLongPress
		}
	};

	return (
		<button
			style={mergedStyle}
			className={className + (className ? " " : "") + "touchable-opacity"}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onClick={handleClick}
			onDoubleClick={handleDoubleClick}
		>
			{children}
		</button>
	);
};

export default TouchableOpacity;