import "./WelcomeScreenModal.css";
import Graduation_Hat from '../../Assets/Images/GraduationHat.png';

import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppThemeContext } from '../../contexts/colors';
import { localeContext } from "../../contexts/localeManagement";
import TouchableOpacity from "../Custom/TouchableOpacity";

import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export default function WelcomeScreenModal({ modalVisible = true, setModalVisible = () => { }, modalValue, onModalValueUp = () => { }, onModalValueDown = () => { }, modalTitle, incrementDisableCondition, decrementDisableCondition, setModalValue = () => { } }) {
	const [isIncrementHovered, setIsIncrementHovered] = React.useState(false)
	const [isDecrementHovered, setIsDecrementHovered] = React.useState(false)

	const {
		dark,
		colors
	} = React.useContext(AppThemeContext);
	const { locale, appText } = React.useContext(localeContext);

	let modalStyles = React.useMemo(() => ({
		dropdownBtnIcon: {
			play: "flex",
			flexDirection: "column",
			alignSelf: "flex-end",
			marginTop: -22
		},
		modalContainer: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			height: "100%",
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: colors().constantBlack + "55"
		},
		modalContent: {
			display: "flex",
			flexDirection: "column",
			backgroundColor: dark ? colors().screen : colors().primary,
			padding: 20,
			justifyContent: 'center',
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 0,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			height: 350 - 77 - 44,
			width: "50%"
		},
		modalText: {
			display: "flex",
			flexDirection: "column",
			color: colors().constantWhite,
			fontWeight: "bold",
			fontSize: 40,
			fontFamily: "Courier New",
		},
		modalHeader: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			backgroundColor: dark ? colors().gray : colors().secondary,
			padding: 20,
			width: "50%",
			borderTopLeftRadius: 20,
			borderTopRightRadius: 20
		},
		modalFooter: {
			display: "flex",
			flexDirection: "column",
			backgroundColor: dark ? colors().gray : colors().secondary,
			border: "none",
			outline: "none",
			padding: 20,
			paddingTop: 10,
			paddingBottom: 10,
			width: "50%",
			borderBottomLeftRadius: 20,
			borderBottomRightRadius: 20
		},
		modal_HF_Text: {
			display: "flex",
			flexDirection: "column",
			color: colors().constantWhite,
			fontFamily: locale === "ar" ? "ArabicMainNormal" : "",
			fontWeight: locale === "ar"
				? 400
				: "bold",
			textAlign: "center",
			marginLeft: "auto",
			marginRight: "auto",
			fontSize: 30,
		},
		toggles: {
			display: "flex",
			flexDirection: "column",
			// backgroundColor: "transparent",
			border: `2px ${colors().gray} solid`,
			outline: "none",
			alignSelf: "flex-end",
			padding: 10,
			justifyContent: "center",
			alignContent: "center",
			transition: "all 200ms ease",
		}
	}), [dark, locale])

	const [initVal, setInitVal] = React.useState("");
	React.useEffect(() => {
		if (modalVisible) {
			setInitVal(modalValue)
		}
	}, [modalVisible])

	const toggleModal = () => {
		setModalVisible(!modalVisible)
		setIsIncrementHovered(false)
		setIsDecrementHovered(false)
	}

	const cancel = () => {
		setModalValue(initVal)
		setModalVisible(!modalVisible)
		setIsIncrementHovered(false)
		setIsDecrementHovered(false)
	}

	return (
		<>
			<Modal
				open={modalVisible}
				onClose={() => {
					cancel();
				}}
			>
				<Fade in={modalVisible}>
					<div style={modalStyles.modalContainer}>
						<div style={modalStyles.modalHeader}>
							<h1 style={modalStyles.modal_HF_Text}>{appText[modalTitle] || modalTitle}</h1>
							<button style={{ border: "none", outline: "none", backgroundColor: "transparent", cursor: "pointer" }}>
								<FontAwesomeIcon icon={faXmark} size="5x" color={colors().constantWhite} style={{ ...modalStyles.dropdownBtnIcon, marginTop: 5 }}
									onClick={() => {
										cancel()
									}}
								/>
							</button>
						</div>
						<div style={modalStyles.modalContent}>
							<img alt="Graduation Hat" src={Graduation_Hat} style={{ width: 200, position: "absolute", resizeMode: "contain", alignSelf: "center", filter: dark ? "invert(50%) sepia(0%) saturate(79%) hue-rotate(159deg) brightness(100%) contrast(90%)" : "invert(83%) sepia(7%) saturate(2163%) hue-rotate(83deg) brightness(92%) contrast(88%)", opacity: 0.67 }} />
							<h1 style={modalStyles.modalText}>{modalValue}</h1>
							<TouchableOpacity style={{ ...modalStyles.toggles, 
								marginTop: -81,
								paddingTop: 20,
								borderTopLeftRadius: 100,
								borderTopRightRadius: 100,
								backgroundColor: !incrementDisableCondition ?
									isIncrementHovered ? colors().tertiary : colors().screen
									: colors().tertiary + "AA"
							}}
								hoverOpacity={0.8}
								disabledOpacity={0.8}
								onPress={() => onModalValueUp()}
								onMouseEnter={() => setIsIncrementHovered(true)}
								onMouseLeave={() => setIsIncrementHovered(false)}
								disabled={incrementDisableCondition}
							>
								<FontAwesomeIcon icon={faChevronUp} size="3x" color={!incrementDisableCondition ?
									isIncrementHovered ? colors().primary : colors().dynamicWhite
									: colors().dynamicWhite + "AA"
								} />
							</TouchableOpacity>

							<TouchableOpacity style={{ ...modalStyles.toggles, 
								paddingBottom: 20,
								borderBottomLeftRadius: 100,
								borderBottomRightRadius: 100,
								backgroundColor: !decrementDisableCondition ?
									isDecrementHovered ? colors().tertiary : colors().screen
									: colors().tertiary + "AA"
							}}
								hoverOpacity={0.8}
								disabledOpacity={0.8}
								onPress={() => onModalValueDown()}
								onMouseEnter={() => setIsDecrementHovered(true)}
								onMouseLeave={() => setIsDecrementHovered(false)}
								disabled={decrementDisableCondition}
							>
								<FontAwesomeIcon icon={faChevronDown} size="3x" color={!decrementDisableCondition ?
									isDecrementHovered ? colors().primary : colors().dynamicWhite
									: colors().dynamicWhite + "AA"
								} />
							</TouchableOpacity>
						</div>
						<TouchableOpacity className="modalFooter" style={modalStyles.modalFooter}
							onPress={() => toggleModal()}
							activeOpacity={0.8}
						>
							<h1 style={{ ...modalStyles.modal_HF_Text, 
								fontSize: 20
							}}>{appText["Select"]}</h1>
						</TouchableOpacity>
					</div>
				</Fade>
			</Modal>
		</>
	);
}