import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import { AppThemeContext } from "../../contexts/colors";
import { localeContext } from "../../contexts/localeManagement";
import { ClassContext } from "../../contexts/classManagement";
import { AuthContext } from "../../contexts/accountManagement";
import { useParams, useLocation, useNavigate, useNavigation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, width } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";

import WebsiteHeader from "../../Components/WebsiteHeader";
import FlatList from "../../Components/Custom/FlatList";
import StudentScreenStudent from "../../Components/Data/StudentScreenStudent";
import UtilityOverlay from "../../Components/Utilities/UtilityOverlay";
import MajorSelection from "../../Components/Utilities/MajorSelection"
import TextField from '@mui/material/TextField';
import TouchableOpacity from "../../Components/Custom/TouchableOpacity";
import { faCheck, faPlus, faUpload, faXmark } from "@fortawesome/free-solid-svg-icons";
import UploadClassSpreadsheet from "./UploadClassSpreadsheet";

const transformClassData = (sourceClassData, setTargetMutatedData) => {
	if (Object.keys(sourceClassData).length === 0) {
		setTargetMutatedData([]);
		return;
	}
	let tempArray = Object.entries(sourceClassData["value"]).map(([student, data]) => ({
		name: student,
		arabicName: data["arabicName"],
		studentId: data["studentId"],
		religion: data["religion"],
		gender: data["gender"]
	}));
	let boysArray = tempArray.filter(student => student.gender === "M");
	let girlsArray = tempArray.filter(student => student.gender === "F");
	boysArray.sort((a, b) => a.arabicName.localeCompare(b.arabicName));
	girlsArray.sort((a, b) => a.arabicName.localeCompare(b.arabicName));
	tempArray = [...boysArray, ...girlsArray]
	setTargetMutatedData(tempArray)
}

/**
 * 
 * @param {{ name: string, arabicName: string, studentId: string, religion: string, gender: string }[]} mutatedClassData 
 * @returns {{[stdName: string]: { arabicName: string, studentId: string, religion: string, gender: string }}}
 */
const revertClassData = (mutatedClassData) => {
	if (!Array.isArray(mutatedClassData) || mutatedClassData.length === 0) {
		return {};
	}

	const revertedObject = mutatedClassData.reduce((acc, student) => {
		acc[student.name] = {
			arabicName: student.arabicName,
			studentId: student.studentId,
			religion: student.religion,
			gender: student.gender
		};
		return acc;
	}, {})

	return revertedObject;
};

const sortByAtoZBtoG = (mutatedClassData) => {
	let boysArray = mutatedClassData.filter(student => student.gender === "M");
	let girlsArray = mutatedClassData.filter(student => student.gender === "F");
	boysArray.sort((a, b) => a.arabicName.localeCompare(b.arabicName));
	girlsArray.sort((a, b) => a.arabicName.localeCompare(b.arabicName));
	let tempArray = [...boysArray, ...girlsArray]
	return tempArray;
}

const generateNewId = (sourceClassData, generationFunc) => {
	let id = generationFunc();

	if (sourceClassData.filter(student => student.studentId === id).length > 0) {
		id = generateNewId(sourceClassData, generationFunc);
	}
	return id;
}

const EmptyList = ({className=""}) => {
	const {
		dark,
		colors
	} = useContext(AppThemeContext);
	const { locale } = useContext(localeContext);
	const [showComponent, setShowComponent] = useState(false);

	let emptyListWarningStyle = useMemo(() => ({
		container: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			justifyContent: "center"
		},
		warningTxt: {
			display: "flex",
			flexDirection: "column",
			fontSize: 25,
			color: colors().dynamicWhite,
			fontFamily: "Courier New",
			textAlign: "center",
			fontWeight: "bold",
			padding: 8
		}
	}), [dark])

	useEffect(() => {
		setTimeout(() => {
			setShowComponent(true);
		}, 500);
	}, [])
	
	return showComponent ? (
		<div style={emptyListWarningStyle.container}>
			<FontAwesomeIcon icon={faQuestionCircle} size="10x" color={colors().warning} style={{ textAlign: "center" }} />
			{
				(() => {
					let comp = <h1 style={emptyListWarningStyle.warningTxt}>Class “{className.replace("-", "/")}” contains 0 students. Start adding students by pressing the plus button!</h1>;
					switch (locale) {
						case "ar":
							comp = <h1 style={{ ...emptyListWarningStyle.warningTxt, direction: "rtl" }}>{"الفصل “" + className.replace("-", "/") + "” يحتوي على 0 طلاب. أبدأ بإضافة الطلاب من خلال الضغط على الزر أسفل الشاشة!"}</h1>
							break;

						case "de":
							comp = <h1 style={emptyListWarningStyle.warningTxt}>Klasse „{className.replace("-", "/")}“ enthält 0 Schüler, Beginnen Sie mit dem Hinzufügen von Schülern durch Drücken der Plus-Taste!</h1>;
							break;
					
						default:
							break;
					}
					return comp;
				})()
			}
		</div>
	) : null
}

const EditStudentForm = forwardRef(function EditStudentForm({ visible, setVisible, onSubmit=() => {} }, ref) {
	const {
		dark,
		colors
	} = useContext(AppThemeContext);
	const [item, setItem] = useState({});
	const [editing, setEditing] = useState(false);

	const [ arabicName, setArabicName ] = useState(item["arabicName"] || "");
	const [ englishName, setEnglishName ] = useState(item["name"] || "");
	const [ religion, setReligion ] = useState(item["religion"] ? (item["religion"] === "M" ? 1 : 2) : null);
	const [ gender, setGender ] = useState(item["gender"] ? (item["gender"] === "M" ? 1 : 2) : null);

	const handleItemChange = (newItem) => {
		console.log("changing item");
		setItem(newItem);
		setArabicName(newItem["arabicName"] || "");
		setEnglishName(newItem["name"] || "");
		setReligion(newItem["religion"] ? (newItem["religion"] === "M" ? 1 : 2) : null);
		setGender(newItem["gender"] ? (newItem["gender"] === "M" ? 1 : 2) : null);
	}

	useImperativeHandle(ref, () => ({
		getItem: () => item,
		setItem: (value) => handleItemChange(value),
		getEditing: () => editing,
		setEditing: (value) => setEditing(value)
	}))

	useEffect(() => {
		const changeTextfieldStyles = async () => {
			await new Promise(resolve => setTimeout(resolve, 0)); // Allow layout to complete

			const normalColor = dark ? colors().lightButton : colors().listItem;
			const focusedColor = colors().success;

			const insertRule = (selector, styles) => {
				const style = document.styleSheets[0];
				const rule = `${selector} { ${styles} }`;
				style.insertRule(rule, style.cssRules.length);
			};

			insertRule('.edit-mawjood-student div:before', `border-bottom: 2px solid ${normalColor} !important;`);
			insertRule('.edit-mawjood-student div:after', `border-bottom: 2px solid ${focusedColor} !important;`);

			insertRule('.edit-mawjood-student div.Mui-error:before', `border-bottom: 2px solid ${colors().danger} !important;`);
			insertRule('.edit-mawjood-student div.Mui-error:after', `border-bottom: 2px solid ${colors().danger} !important;`);

			insertRule('.edit-mawjood-student div', `background-color: ${colors().gray} !important;`);
			insertRule('.edit-mawjood-student p', `font-weight: bold; font-family: monospace; letter-spacing: unset; font-size: 15px; color: ${colors().danger} !important;`);

			insertRule('.edit-mawjood-student label', `color: ${normalColor} !important; font-weight: bold`);
			insertRule('.edit-mawjood-student label.Mui-focused', `color: ${focusedColor} !important;`);
			insertRule('.edit-mawjood-student label.Mui-error', `color: ${colors().danger} !important;`);

			insertRule('#arabic-name, #english-name', `color: white !important; font-weight: bold`);
			insertRule('#arabic-name', `text-align: right; direction: rtl !important;`);
		}

		changeTextfieldStyles();
	}, [dark]);

	const handleSubmit = () => {
		if (englishName.trim().split(" ").length < 4 || arabicName.trim().split(" ").length < 4 || !gender || !religion) {
			return;
		}

		const newItem = item;
		newItem["arabicName"] = arabicName;
		newItem["name"] = englishName;
		newItem["religion"] = religion === 1 ? "M" : "C";
		newItem["gender"] = gender === 1 ? "M" : "F";

		setItem(newItem);

		onSubmit(newItem);
	}

	return (
		<UtilityOverlay
			modalVisible={visible}
			setModalVisible={setVisible}
			modalTitle="Edit Student"
			CTA="Continue"
			CTAResult={handleSubmit}
			CTADisabled={englishName.trim().split(" ").length < 4 || arabicName.trim().split(" ").length < 4 || !gender || !religion}
			useChildren
			contentHeight={420}
		>
			{/* <p>{(englishName.trim().split(" ").length < 4 && arabicName.trim().split(" ").length < 4
				? "Values"
				: englishName.trim().split(" ").length < 4
					? "English Name"
					: "Arabic Name") + " must be of at least 4 names!"}</p> */}
			<TextField
				id="arabic-name"
				className="edit-mawjood-student"
				// error={arabicName.trim().split(" ").length < 4}
				// helperText={arabicName.trim().split(" ").length < 4 ? "Arabic Name must be of at least 4 names!" : ""}
				value={arabicName}
				onChange={e => setArabicName(e.target.value)}
				label="Student's Arabic Name"
				variant="filled"
				style={{ marginBottom: 10 }}
			/>
			<TextField
				id="english-name"
				className="edit-mawjood-student"
				error={englishName.trim().split(" ").length < 4}
				helperText={englishName.trim().split(" ").length < 4 ? "English Name must be of at least 4 names!" : ""}
				value={englishName}
				onChange={e => setEnglishName(e.target.value)}
				label="Student's English Name"
				variant="filled"
				style={{ marginBottom: 20 }}
			/>
			<MajorSelection
				changeLightColor
				width="90%"
				isSwitch
				firstOptionData={{ icon: "star-and-crescent", color: "green" }}
				secondOptionData={{ icon: "cross", color: dark ? "#653294" : "#803ebd" }}
				switchValue={religion}
				setSwitchValue={setReligion}
			/>
			<MajorSelection
				changeLightColor
				width="90%"
				isSwitch
				firstOptionData={{ icon: "mars", color: "#0095ff" }}
				secondOptionData={{ icon: "venus", color: "hotpink" }}
				switchValue={gender}
				setSwitchValue={setGender}
				lastItem
			/>
		</UtilityOverlay>
	)
})

export default function ViewClass() {
	const params = useParams();

	useEffect(() => {
		document.title = `Mawjood Classes | ${params.className.replace("-", "/")}`;
	}, [params]);
	
	const navigate = useNavigate();
	const location = useLocation();
	const hasClearedState = useRef(false); // Ref to track clearing action

	const {
		dark,
		colors
	} = useContext(AppThemeContext);
	const { locale, appText } = useContext(localeContext);
	const {
		currentClassData,
		getClass,
		genStudentId,
		createClass,
		editClass
	} = useContext(ClassContext);
	const { loggedIn, userData } = useContext(AuthContext);

	//* Animation
	const [ scale, setScale ] = useState(1);
	const [ checkScale, setCheckScale ] = useState(0);
	const [ xScale, setXScale ] = useState(0);
	const [ parentScale, setParentScale ] = useState(1);
	
	//* States
	const [ mutatedClassData, setMutatedClassData ] = useState([]);
	const [ zeroL, setZeroL ] = useState(true);

	const [ dataChanged, setDataChanged ] = useState(false);
	const [ loading, setLoading ] = useState(true);

	const [ className, setClassName ] = useState({...currentClassData}["name"] || params.className || "N-A");

	//^ Form States
	const [ editFormVis, setEditFormVis ] = useState(false);
	const formRef = useRef(null);

	//^ Form States
	const [ uploadSpreadsheetVis, setUploadSpreadsheetVis ] = useState(false);
	const spreadsheetRef = useRef(null);

	//^ UtilityOverlay States
	const [classCreateData, setClassCreateData] = useState({
		title: "",
		message: "",
		callToAction: "",
		success: false
	});
	const [utilityErrorVis, setUtilityErrorVis] = useState(false);

	const getSchoolName = () => {
		switch (locale) {
			case "en":
				return process.env.REACT_APP_SCHOOL_NAME_EN;
			case "ar":
				return process.env.REACT_APP_SCHOOL_NAME_AR;
			case "de":
				return process.env.REACT_APP_SCHOOL_NAME_DE;
			case "fr":
				return process.env.REACT_APP_SCHOOL_NAME_FR;
			default:
				return process.env.REACT_APP_SCHOOL_NAME_EN;
		}
	}

	let classViewerStyles = useMemo(() => ({
		container: {
			display: "flex",
			flex: 1,
			flexDirection: "column",
			backgroundColor: colors().screen,
			justifyContent: "center",
			alignContent: "center",
			height: "100vh"
		}
	}), [dark, locale]);

	//* useEffects
	useEffect(() => {
		transformClassData(currentClassData, setMutatedClassData);
	}, [currentClassData]);

	useEffect(() => {
		//* console.log(mutatedClassData.length);
		if (mutatedClassData.length === 0 && zeroL) {
			setLoading(false);
			return;
		}
		if (mutatedClassData.length > 0 && zeroL) {
			const TIME_FACTOR = 17;
			const ARRAY_LENGTH = mutatedClassData.length;
			const TIME_DELAY = (Math.floor((ARRAY_LENGTH / TIME_FACTOR) * 10) / 10) * 1000;

			//* console.log(ARRAY_LENGTH, ARRAY_LENGTH / TIME_FACTOR, TIME_DELAY / 1000, "from inside");
			const timer = setTimeout(() => {
				setLoading(false);
			}, TIME_DELAY);

			setZeroL(false);
			return () => clearTimeout(timer);
		}
	}, [mutatedClassData.length]);

	useEffect(() => {
		if ((hasClearedState.current) || (!loggedIn)) {
			// State clearing already handled, skip further checks
			console.log("has cleared state is true")
			return;
		}

		const fromApp = !!location.state?.fromApp;

		if (fromApp) {
			console.log("Navigated from app");
			hasClearedState.current = true; // Mark as handled
			navigate(location.pathname, { replace: true }); // Clear state without reloading
		} else {
			setLoading(true);
			getClass(className).then(() => setLoading(false));
			console.log("Direct visit or refresh")
			hasClearedState.current = true;
		}
	}, [hasClearedState.current, loggedIn]);

	/**
	 * @type {() => Promise<void>}
	 */
	const submitNewClass = useCallback(async () => {
		if (!dataChanged) return navigate(`/account/${userData["name"]}?Tab=Class%20Data&Class=${className}`);
		if (Object.keys(currentClassData).length === 0) {
			await createClass(className, revertClassData(mutatedClassData)).then(res => {
				if (res.success) return navigate(`/account/${userData["name"]}?Tab=Class%20Data&Class=${className}`);
				setClassCreateData({
					title: appText["Error"],
					message: res.message,
					callToAction: res.callToAction,
					success: false
				});
				setUtilityErrorVis(true);
			});
			return;
		}
		await editClass(className, revertClassData(mutatedClassData)).then(res => {
			if (res.success) return navigate(`/account/${userData["name"]}?Tab=Class%20Data&Class=${className}`);
			setClassCreateData({
				title: appText["Error"],
				message: res.message,
				callToAction: res.callToAction,
				success: false
			});
			setUtilityErrorVis(true);
		});
	}, [dataChanged, mutatedClassData, currentClassData]);

	/**
	 * @type {(stdId: string) => void}
	 */
	const deleteStd = useCallback((stdId) => {
		//* console.log("starting");
		setMutatedClassData((currentData) => {
			let newData = currentData.filter(std => std.studentId !== stdId);
			//* console.log("sorting");
			// Assuming sortByAtoZBtoG doesn't rely on external state and is pure.
			return sortByAtoZBtoG(newData);
		});
		//* console.log("setting");
		setDataChanged(true);
		//* console.log("done");
	}, [mutatedClassData]);

	const submit = React.useCallback(() => {
		const growBackAsCheck = () => setTimeout(() => {
			setCheckScale(1);
			setTimeout(() => {
				submitNewClass();
			}, 300);
		}, 300);
		if (mutatedClassData.length > 0) {
			setParentScale(1.5);
			setTimeout(() => {
				setScale(0);
				setTimeout(() => {
					growBackAsCheck()
				}, 300);
			}, 500 + 400);
		} else {
			setTimeout(() => {
				setScale(0);
				setTimeout(() => {
					setXScale(1);
					setTimeout(() => {
						setXScale(0);
						setTimeout(() => {
							setScale(1);
						}, 300);
					}, 300 + 3000);
				}, 300 + 300);
			}, 400);
		}
	}, [mutatedClassData])

	/**
	 * @type {() => (stdId: string) => void}
	 */
	const handleDelete = useCallback(() => {
		if (className !== "N-A") {
			return deleteStd;
		}
	}, [className, deleteStd]);

	/**
	 * @type {(item: { name: string, arabicName: string, studentId: number, religion: string, gender: string }) => () => void}
	 */
	const handleEdit = useCallback((item) => () => {
		if (className !== "N-A") {
			formRef.current.setItem(item);
			formRef.current.setEditing(true);
			setEditFormVis(true);
		}
	}, [className, mutatedClassData]);

	const renderItems = useCallback(({ item, index }) => (
		<StudentScreenStudent
			name={item.arabicName}
			englishName={item.name}
			gender={item.gender}
			forAttendance={false}
			religion={item.religion}
			extraMarginBottom={index === mutatedClassData.filter((student) => student.gender === "M").length - 1}
			stdId={item.studentId}
			onDelete={handleDelete()}
			onCopyArabic={() => navigator.clipboard.writeText(item.arabicName)}
			onCopyEnglish={() => navigator.clipboard.writeText(item.name)}
			onEdit={handleEdit(item)}
		/>
	), [mutatedClassData, handleDelete, handleEdit]);

	const onFormSubmit = useCallback((item) => {
		if (!item["name"] || !item["arabicName"] || !item["religion"] || !item["gender"]) return;
		if (formRef.current.getEditing() === true && item["studentId"]) {
			let newData = [...mutatedClassData];
			newData = newData.filter(student => student.studentId !== item["studentId"]);
			newData.push({
				name: item["name"],
				arabicName: item["arabicName"],
				studentId: item["studentId"],
				religion: item["religion"],
				gender: item["gender"]
			});
			setMutatedClassData(sortByAtoZBtoG(newData));
			setDataChanged(true);
			return;
		}
		const newStdId = generateNewId(mutatedClassData, genStudentId);
		let newData = [...mutatedClassData];

		newData.push({
			name: item["name"],
			arabicName: item["arabicName"],
			studentId: newStdId,
			religion: item["religion"],
			gender: item["gender"]
		})

		setMutatedClassData(sortByAtoZBtoG(newData));
		setDataChanged(true);
	}, [formRef.current, mutatedClassData]);

	const onSpreadsheetSubmit = (classValue) => {
		console.log(classValue);
		const data = { value: classValue };
		transformClassData(data, setMutatedClassData);
		setDataChanged(true);
	}

	return (
		<>
			<WebsiteHeader
				showAppName={false}
				headerTitle={getSchoolName()}
				// forceBG
			/>
			<div style={classViewerStyles.container}>
				{loading
					? (<FlatList
						data={[0, 1, 2, 3, 4, 5, 6]}
						renderItem={() => {
							return (
								<StudentScreenStudent skeleton />
							)
						}}
						contentContainerStyle={{ marginTop: 118, paddingTop: 20 }}
					/>)
					: (<FlatList
						data={mutatedClassData}
						keyExtractor={(item) => item.studentId.toString()}
						renderItem={renderItems}
						ListEmptyComponent={<EmptyList className={className} />}
						contentContainerStyle={{ marginTop: 118, paddingTop: 20 }}
						initialNumToRender={30} //* Render a small number initially
						maxToRenderPerBatch={15} //* Render more items in batches of 15
						updateCellsBatchingPeriod={1} //* Update items at intervals of 10 milliseconds
						// getItemLayout={(data, index) => (
						// 	{length: 90, offset: 90 * index, index}
						// )}
						shouldUseInteractionManager
					/>)}
			</div>
			<TouchableOpacity
				style={{
					position: "absolute",
					bottom: 50,
					right: 30,
					width: 80,
					height: 80,
					backgroundColor: dark ? colors().mawjoodOther : colors().mawjoodMain,
					padding: 10,
					borderRadius: 70 / 4,
					justifyContent: "center",
					alignItems: "center"
					//[locale === "ar" ? "borderBottomLeftRadius" : "borderBottomRightRadius"]: 0
				}}
				activeOpacity={0.7}
				onPress={className === "N-A" ? () => null : () => {
					formRef.current.setEditing(false);
					setEditFormVis(true);
				}}
			>
				<div>
					<FontAwesomeIcon icon={faPlus} color={dark ? colors().mawjoodMain : colors().mawjoodOther} size="3x" style={{ marginTop: "auto", marginBottom: "auto", marginRight: "auto", marginLeft: "auto" }} />
				</div>
			</TouchableOpacity>
			<TouchableOpacity
				style={{
					display: "flex",
					flexDirection: "column",
					position: "absolute",
					bottom: 62.5,
					height: 50,
					width: 300,
					backgroundColor: dark ? colors().mawjoodOther : colors().mawjoodMain,
					padding: 15,
					borderRadius: 70 / 4,
					justifyContent: "center",
					alignItems: "center",
					alignSelf: "center"
				}}
				activeOpacity={0.7}
				disabled={!loggedIn}
				onPress={() => setUploadSpreadsheetVis(true)}
			>
				<h1 style={{ fontSize: 20, fontFamily: "Ubuntu", fontWeight: "bold", color: dark ? colors().mawjoodMain : colors().mawjoodOther, }}>Import data from excel</h1>
			</TouchableOpacity>
			<TouchableOpacity
				style={{
					position: "absolute",
					bottom: 50,
					left: 30,
					width: 80,
					height: 80,
					backgroundColor: dark ? colors().mawjoodOther : colors().mawjoodMain,
					padding: 10,
					borderRadius: 70 / 4,
					transform: `scale(${parentScale})`,
					transition: "all 500ms ease",
					justifyContent: "center",
					alignItems: "center"
					//[locale === "ar" ? "borderBottomLeftRadius" : "borderBottomRightRadius"]: 0
				}}
				activeOpacity={0.7}
				onPress={submit}
			>
				<div style={{ transform: `scale(${scale})`, transition: "all 300ms ease", }}>
					<FontAwesomeIcon icon={faUpload} color={dark ? colors().mawjoodMain : colors().mawjoodOther} size="3x" style={{ marginTop: "auto", marginBottom: "auto", marginRight: "auto", marginLeft: "auto" }} />
				</div>
				<div style={{ position: "absolute", bottom: "25%", left: "25%", transform: `scale(${checkScale})`, transition: "all 300ms ease", }}>
					<FontAwesomeIcon icon={faCheck} color={dark ? colors().mawjoodMain : colors().mawjoodOther} size="3x" style={{ marginTop: "auto", marginBottom: "auto", marginRight: "auto", marginLeft: "auto" }} />
				</div>
				<div style={{ position: "absolute", bottom: "20px", left: "25px", transform: `scale(${xScale})`, transition: "all 300ms ease" }}>
					<FontAwesomeIcon icon={faXmark} color={colors().danger} size="3x" style={{ marginTop: "auto", marginBottom: "auto", marginRight: "auto", marginLeft: "auto" }} />
				</div>
			</TouchableOpacity>
			<EditStudentForm onSubmit={onFormSubmit} ref={formRef} visible={editFormVis} setVisible={setEditFormVis} />
			<UploadClassSpreadsheet onSubmit={onSpreadsheetSubmit} visible={uploadSpreadsheetVis} setVisible={setUploadSpreadsheetVis} />
			<UtilityOverlay
				modalContent={classCreateData.message + (!classCreateData.message.startsWith("Unexpected") ? "" : ` See devlogs or contact principal. Please try again later.`)}
				modalTitle={classCreateData.title}
				modalVisible={utilityErrorVis}
				setModalVisible={setUtilityErrorVis}
				CTA={classCreateData.callToAction}
				CTAResult={() => navigate(`/account/${userData["name"]}?Tab=Class%20Data&Class=${className}`)}
				colorPrimary={classCreateData.success ? "" : colors().danger}
				colorSecondary={classCreateData.success ? "" : colors().danger + "A2"}
				xVisible={false}
				fontSize={25}
			/>
		</>
	);
}