import React, { useContext, useEffect, useMemo, useState } from "react";

import { AppThemeContext } from "../../contexts/colors";
import { localeContext } from "../../contexts/localeManagement";
import { ClassContext } from "../../contexts/classManagement";

import MajorSelection from "../../Components/Utilities/MajorSelection";
import UtilityOverlay from "../../Components/Utilities/UtilityOverlay";
import WelcomeScreenModal from "../../Components/Utilities/WelcomeScreenModal";
import TouchableOpacity from "../../Components/Custom/TouchableOpacity";
import PropagateLoader from "react-spinners/PropagateLoader";

import { useLocation, useNavigate } from "react-router-dom";

export default function ClassData() {
	const {
		dark,
		colors
	} = useContext(AppThemeContext);
	const { locale, appText } = useContext(localeContext);
	const { classManagerBusy, getClass } = useContext(ClassContext);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const navigate = useNavigate();

	const classDataStyles = useMemo(() => ({
		container: {
			display: "flex",
			flex: 1,
			flexDirection: "column",
			backgroundColor: colors().screen,
			justifyContent: "center",
			alignContent: "center",
			height: "100vh"
		},
		btn: {
			padding: 10,
			backgroundColor: dark ? colors().constantWhite : colors().mawjoodMain,
			width: "30%",
			marginLeft: "auto",
			marginRight: "auto",
			borderRadius: 15,
			height: 52,

			display: "flex",
			justifyContent: "center",
			alignContent: "center",
			alignItems: "center",
			flexDirection: "row",
			border: "none",
			outline: "none",

			transition: "300ms all ease"
		},
		btnText: {
			color: dark ? colors().mawjoodMain : colors().listItem,
			fontSize: 22,
			textAlign: "center",
			fontFamily: locale === "ar" ? "ArabicMainBold" : "Ubuntu",
			fontWeight: locale === "ar"
				? "regular"
				: "bold",
		}
	}), [dark, locale]);

	//* Modal States
	const [yearModalVis, setYearModalVis] = React.useState(false);
	const [classModalVis, setClassModalVis] = React.useState(false);

	//* Modal Values
	const [year, setYear] = React.useState(1);
	const [classLetter, setClassLetter] = React.useState("A");

	const handleYearIncrease = () => {
		if (year < 12) {
			setYear(year + 1)
		} else {
			setYear(12)
		}
	}

	const handleYearDecrease = () => {
		if (year > 1) {
			setYear(year - 1)
		} else {
			setYear(1)
		}
	}

	const classCycle = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

	const classIncrease = () => {
		if (classLetter !== "Z") {
			setClassLetter(classCycle[classCycle.indexOf(classLetter) + 1])
		}
	}

	const classDecrease = () => {
		if (classLetter !== "A") {
			setClassLetter(classCycle[classCycle.indexOf(classLetter) - 1])
		}
	}

	const createNewClass = async (className) => {
		await getClass(className).then(() => navigate(`/class/${className}`, { state: { fromApp: "true" } }));
	}

	useEffect(() => {
		const classQ = queryParams.get('Class');

		if (!classQ) return;

		if (classQ?.split("-")?.length === 2 && parseInt(classQ?.split("-")?.[0]) >= 1 && classQ?.split("-")?.[1]?.length === 1) {
			setYear(classQ.split("-")[0]);
			setClassLetter(classQ.split("-")[1].toUpperCase());
		}
	}, [location])

	return (
		<>
			<div style={classDataStyles.container}>
				<MajorSelection content={"Year"} secondaryText={`(${year})`} onPress={() => setYearModalVis(true)} />
				<MajorSelection content={"Class"} secondaryText={`(${classLetter})`} onPress={() => setClassModalVis(true)} />
				<TouchableOpacity
					style={{ ...classDataStyles.btn, 
						marginTop: 30,
						backgroundColor: !(classManagerBusy)
							? dark ? colors().constantWhite : colors().mawjoodMain
							: dark ? colors().constantWhite + "AA" : colors().mawjoodMain + "AA"
					}}
					onPress={() => createNewClass(`${year}-${classLetter}`)}
					disabled={classManagerBusy}
					activeOpacity={0.7}
				>
					<h1 style={{ ...classDataStyles.btnText, 
						color: !(classManagerBusy)
							? dark ? colors().mawjoodMain : colors().listItem
							: dark ? colors().mawjoodMain + "AA" : colors().listItem + "AA"
					}}>{classManagerBusy
						? <PropagateLoader size={10} color={dark ? colors().mawjoodMain + "AA" : colors().listItem + "AA"} cssOverride={{ textAlign: "center" }} />
						: appText["Continue"]}</h1>
				</TouchableOpacity>
			</div>

			<WelcomeScreenModal
				modalTitle="Select A Year"
				modalValue={year}
				modalVisible={yearModalVis}
				setModalVisible={setYearModalVis}
				onModalValueDown={() => handleYearDecrease("year")}
				onModalValueUp={() => handleYearIncrease("year")}
				incrementDisableCondition={year >= 12}
				decrementDisableCondition={year <= 1}
				setModalValue={setYear}
			/>

			<WelcomeScreenModal
				modalTitle="Select A Class"
				modalValue={classLetter}
				setModalValue={setClassLetter}
				modalVisible={classModalVis}
				setModalVisible={setClassModalVis}
				onModalValueDown={() => classDecrease()}
				onModalValueUp={() => classIncrease()}
				incrementDisableCondition={classLetter === "Z"}
				decrementDisableCondition={classLetter === "A"}
			>
				<h1>hi</h1>
			</WelcomeScreenModal>
		</>
	);
}