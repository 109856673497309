import "../Auth/LoginScreen.css";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AppThemeContext } from "../../contexts/colors";
import { localeContext } from "../../contexts/localeManagement";
import { AuthContext } from "../../contexts/accountManagement";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import WebsiteHeader from "../../Components/WebsiteHeader";
import TeacherAccScreen from "../Admin/TeacherAccScreen";
import LogViewerScreen from "./LogViewerScreen";
import ClassData from "../Tools/ClassData";
import PuffLoader from "react-spinners/PuffLoader";

const getChildComponent = (activeTab, loggedIn, waitingColor, waitingText) => {
	if (!loggedIn) {
		return (
			<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignSelf: "center" }}>
				<div style={{ marginLeft: "auto", marginRight: "auto" }}>
					<PuffLoader size={100} color={waitingColor} />
				</div>
				<h1
					style={{
						fontSize: 25,
						color: waitingColor,
						textAlign: "center",
						marginTop: 10,
						fontFamily: "monospace",
						fontWeight: "bold"
					}}
				>
					<br />
					Waiting for login...
				</h1>
			</div>
		)
	}
	return (
		<>
			{activeTab === 0 && <h2>Under Const.</h2>}
			{activeTab === 1 && <ClassData />}
			{activeTab === 2 && <LogViewerScreen />}
			{activeTab === 3 && <h2>Data Export</h2>}
		</>
	)
}

export default function SettingsScreen() {
	const params = useParams();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	useEffect(() => {
		document.title = `Mawjood | ${params.accountName}`;
	}, [params])

	const {
		dark,
		colors
	} = useContext(AppThemeContext);
	const { locale, appText } = useContext(localeContext);
	const [ activeTab, setActiveTab ] = useState(0);
	const [ drawerOpen, setDrawerOpen ] = useState(false);

	const { loggedIn, uId, userData } = useContext(AuthContext);
	const navigate = useNavigate();
	useEffect(() => {
		if (typeof localStorage.getItem("uId") !== "string") {
			navigate("/");
		}
		if (loggedIn && (userData["name"] !== params.accountName)) {
			navigate("/");
		}
	}, [loggedIn, uId])

	const getSchoolName = () => {
		switch (locale) {
			case "en":
				return process.env.REACT_APP_SCHOOL_NAME_EN;
			case "ar":
				return process.env.REACT_APP_SCHOOL_NAME_AR;
			case "de":
				return process.env.REACT_APP_SCHOOL_NAME_DE;
			case "fr":
				return process.env.REACT_APP_SCHOOL_NAME_FR;
			default:
				return process.env.REACT_APP_SCHOOL_NAME_EN;
		}
	}

	const changeActiveTab = (tab) => {
		setActiveTab(tab);
		setDrawerOpen(false);
	}
	
	let settingsStyles = useMemo(() => ({
		container: {
			display: "flex",
			flex: 1,
			flexDirection: "column",
			backgroundColor: colors().screen,
			justifyContent: "center",
			alignContent: "center",
			height: "100vh"
		}
	}), [dark, locale]);

	useEffect(() => {
		const Tab = queryParams.get('Tab');
		const tabArray = ["Teacher Accounts", "Class Data", "Logs", "Data Export"];
		if (tabArray.includes(Tab)) {
			changeActiveTab(tabArray.indexOf(Tab));
		}
	}, [location]);

	return (
		<>
			<WebsiteHeader
				showAppName={false}
				headerTitle={getSchoolName()}
				drawer
				drawerTitle={appText["Settings"]}
				drawerOpen={drawerOpen}
				setDrawerOpen={setDrawerOpen}
				drawerItems={[{
						text: appText["Teacher Accounts"],
						onClick: () => {
							changeActiveTab(0);
						},
						active: activeTab === 0
					}, {
						text: appText["Class Data"],
						onClick: () => {
							changeActiveTab(1);
						},
						active: activeTab === 1
					}, {
						text: appText["Logs"],
						onClick: () => {
							changeActiveTab(2);
						},
						active: activeTab === 2
					}, {
						text: appText["Data Export"],
						onClick: () => {
							changeActiveTab(3);
						},
						active: activeTab === 3
					}
				]}
				forceBG={activeTab !== 2}
			/>
			<div style={settingsStyles.container}>
				{getChildComponent(activeTab, loggedIn, colors().mawjoodOther)}
			</div>
		</>
	);
}