import {
	createContext,
	useState
} from "react";
import Identicon from "identicon.js";
import WebKit from "../utils/web-kit";
import CommonCrypto from "../utils/common";

export const AuthContext = createContext();
global.Buffer = global.Buffer || require("buffer").Buffer;

async function waitAndContinue(ms) {
	await new Promise(resolve => setTimeout(resolve, ms));
}

const setItemWithExpiry = (key, value, expiryTimeInMs) => {
	const now = new Date();

	// Create an object with the value and the expiry timestamp
	const item = {
		value: value,
		expiry: now.getTime() + expiryTimeInMs, // expiry time in milliseconds
	};

	localStorage.setItem(key, JSON.stringify(item));
};

const getItemWithExpiry = (key) => {
	const itemStr = localStorage.getItem(key);

	// If the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}

	const item = JSON.parse(itemStr);
	const now = new Date();

	// If the current time is past the expiration time, remove the item
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key);
		return null;
	}

	return item.value;
};

export function AuthProvider({
	children
}) {
	const [loggedIn, setLoggedIn] = useState(false);
	const [uId, setUId] = useState("");
	const keyHeader = {
		apikey: "mawjoodStorage-Mr8Y9rInYA9yHr0lFYGT",
	};
	const [loggingIn, setLoggingIn] = useState(false);
	const [preLoggedIn, setPreLoggedIn] = useState(false);
	const [statusText, setStatusText] = useState("");

	const [globalB64_PubKey, setGlobalB64_PubKey] = useState("");
	const [userData, setUserData] = useState({});
	const [identicon, setIdenticon] = useState("");

	const [source, setSource] = useState("");
	const [blobSha, setBlobSha] = useState("");

	const apiUrl = process.env.REACT_APP_API_URL;

	/**
	 * 
	 * @param {string} key 
	 * @param {string} password 
	 * @param {"Username" | "Email"} mode 
	 * @returns {Promise<{ success: boolean, message: string, data: { name?: string, uId?: number, pfp?: string, contacts?: [], level?: number, bio?: string, email?: string, pass?: string } }>} 
	 */
	const logIn = async (key, password, mode) => {
		let res;
		
		setPreLoggedIn(true);
		
		setStatusText("Generating encryption keys...");
		await waitAndContinue(100);
		const cryptographer = new WebKit(CommonCrypto.RSAPublicKey, "", true);
		await cryptographer.importKeys();
		
		let nonce = Array.from(crypto.getRandomValues(new Uint8Array(32)), byte => byte.toString(16).padStart(2, '0')).join('');
		const localPublicKey = localStorage.getItem("publicKey");
		const { publicKey, privateKey } = localPublicKey ? { publicKey: localPublicKey, privateKey: localStorage.getItem("privateKey") } : await WebKit.generateRSAKeypair();
		//* console.log(localPublicKey, publicKey, localPublicKey === publicKey)

		if (!localPublicKey) {
			localStorage.setItem("publicKey", publicKey);
			localStorage.setItem("privateKey", privateKey);
		}
		
		const b64_pubKey = Buffer.from(publicKey).toString("base64");
		setGlobalB64_PubKey(b64_pubKey);
		
		setStatusText("Sending request...");
		await fetch(`${apiUrl}/auth/login`, {
			method: "POST",
			headers: {
				pass: (await cryptographer.encryptWithPublicKey(nonce + password)).hex,
				nonce,
				pubKey: b64_pubKey,
				...keyHeader,
				...(mode === "Email" ? { email: key } : {})
			},
			...(mode === "Username" ? { body: key } : {})
		})
			.then(r => r.text())
			.then(dat => res = dat).catch(e => res = e)

		setStatusText("Validating response...");
		await waitAndContinue(300);
		let resJson;
		try {
			resJson = await JSON.parse(res);
		} catch (e) {
			resJson = res;
		}
		
		if (!resJson["encrypted_session_token"]) {
			setPreLoggedIn(false);
			setUserData({});
			setLoggedIn(false);
			await waitAndContinue(50);
			setStatusText("");
			//* console.error(res);
			return {
				success: false,
				message: res.toString().split(":")[1]?.trim() || res
			}
		}
		
		const userCryptographer = new WebKit(publicKey, privateKey);
		await userCryptographer.importKeys();
		
		const retrievedAESKeyBuffer = Buffer.from(resJson["encrypted_aes_key"], "hex");
		const decryptedAESKeyBuffer = await userCryptographer.decryptWithPrivateKey(retrievedAESKeyBuffer);
		const decryptedAESKey = Buffer.from(decryptedAESKeyBuffer, "base64");
		const AES_CryptoKey = await crypto.subtle.importKey(
			'raw',
			decryptedAESKey,
			{
				name: "AES-CBC",
				length: 256
			},
			false,
			["encrypt", "decrypt"]
		);
		
		await waitAndContinue(50);
		setStatusText("Saving data...");
		await waitAndContinue(300);
		//* console.log(res["encrypted_session_token"]);
		let retrievedSessionTokenBuffer = userCryptographer.retrieveBufferFromCipherHex(resJson["encrypted_session_token"]);
		let iv = atob(resJson["AES_IV"]);
		let ivBytes = new Uint8Array(iv.length);
		
		for (let i = 0; i < iv.length; i++) {
			ivBytes[i] = iv.charCodeAt(i);
		}
		iv = ivBytes.buffer;
		const decryptedSessionToken = await WebKit.decryptUsingAES_CBC(retrievedSessionTokenBuffer, AES_CryptoKey, iv);

		let jwtPayload = WebKit.getJwtPayload(decryptedSessionToken);
		
		setItemWithExpiry("userSessionToken", decryptedSessionToken, (jwtPayload.exp - jwtPayload.iat) * 1000);
		setItemWithExpiry("userAESKey", decryptedAESKey.toString("base64"), (jwtPayload.exp - jwtPayload.iat) * 1000);
		
		let id = jwtPayload.sub;
		localStorage.setItem("uId", id + "")
		//* console.log(id);
		setStatusText("");
		await loadData(id + "")
		return {
			success: true,
			message: "Logged In",
			data: res["document"]
		}
	}

	const logOut = async () => {
		setLoggedIn(false);
		setLoggingIn(true);

		const userId = uId;

		const b64_pubKey = globalB64_PubKey;

		const B64_AESKey = getItemWithExpiry("userAESKey");

		const sessionToken = getItemWithExpiry("userSessionToken");
		const AES_CryptoKey = await crypto.subtle.importKey(
			'raw',
			Buffer.from(B64_AESKey, "base64"),
			{
				name: "AES-CBC",
				length: 256
			},
			false,
			["encrypt", "decrypt"]
		);
		const encryptedSessionToken = await WebKit.encryptUsingAES_CBC(sessionToken, AES_CryptoKey);

		let erroredOut = false;
		//* console.log({
		//* 	...keyHeader,
		//* 	id: userId,
		//* 	pubKey: b64_pubKey,
		//* 	"Authorization": `Bearer ${encryptedSessionToken.ciphertext}`,
		//* 	iv: encryptedSessionToken.iv
		//* })
		await fetch(`${apiUrl}/auth/logout`, {
			method: "POST",
			headers: {
				...keyHeader,
				id: userId,
				pubKey: b64_pubKey,
				"Authorization": `Bearer ${encryptedSessionToken.ciphertext}`,
				iv: encryptedSessionToken.iv
			}
		})
			.then(r => {
				if (r.ok) {
					erroredOut = false;
				} else {
					erroredOut = true;
					r.text()//* .then(console.error);
				}
			})
			.catch(e => {
				//* console.error(e);
				erroredOut = true;
			});
		if (erroredOut) {
			setLoggedIn(true);
			return setLoggingIn(false);
		}
		setPreLoggedIn(false)
		setUserData({})
		setUId("")
		setIdenticon("")
		setSource("")
		setBlobSha("")
		localStorage.removeItem("uId")
		localStorage.removeItem("userSessionToken");
		localStorage.removeItem("userAESKey");

		//* setTimeout(() => {
			setLoggingIn(false)
		//* }, 500);
	}

	/**
	 * Load the client user data from the server
	 * @param {Boolean} fromLogin defines whether or not this function is called from a Login operation
	 * @param {{name?: string, uId?: number, pfp?: string, contacts?: [], level?: number, bio?: string, email?: string, pass?: string}} loginData must be provided if `fromLogin` is true
	 * @param {string} providedId The id of the user to load, `[REQUIRED]`, if not provided will immediately return failed
	 */
	const loadData = async (providedId) => {
		setLoggingIn(true);
		let userId = providedId;

		if (!userId) {
			return setLoggingIn(false);
		}

		const publicKey = localStorage.getItem("publicKey");
		const b64_pubKey = Buffer.from(publicKey).toString("base64");

		const B64_AESKey = getItemWithExpiry("userAESKey");

		const sessionToken = getItemWithExpiry("userSessionToken");
		const AES_CryptoKey = await crypto.subtle.importKey(
			'raw',
			Buffer.from(B64_AESKey, "base64"),
			{
				name: "AES-CBC",
				length: 256
			},
			false,
			["encrypt", "decrypt"]
		);
		const encryptedSessionToken = await WebKit.encryptUsingAES_CBC(sessionToken, AES_CryptoKey);

		let res = {};
		await fetch(`${apiUrl}/auth/load`, {
				headers: {
					...keyHeader,
					id: userId,
					pubKey: b64_pubKey,
					"Authorization": `Bearer ${encryptedSessionToken.ciphertext}`,
					iv: encryptedSessionToken.iv
				}
			})
			.then(r => r.json())
			.then(dat => res = dat).catch(e => res = e);

		if (!res["document"]) {
			setLoggedIn(false);
			setUId("");
			setLoggingIn(false);
			return
		}
		const uIdEncoded = new TextEncoder().encode(userId);
		let uIdHashBuffer = await crypto.subtle.digest("SHA-256", uIdEncoded)

		const uIdHashArray = Array.from(new Uint8Array(uIdHashBuffer))
		const uIdHash = uIdHashArray
			.map((b) => b.toString(16).padStart(2, "0"))
			.join("")

		let tempIdenticon = new Identicon(uIdHash, {
			foreground: [120, 214, 147, 255],
			background: [29, 45, 68, 255],
			margin: 0.2,
			size: 60,
			format: 'svg'
		}).toString();
		
		/**
		 * @type {string} source
		 */
		let source = res["document"]["pfp"] ? res["document"]["pfp"] : tempIdenticon;
		if (source.startsWith("repo://")) {
			await fetch(`https://api.github.com/repos/Proman4713/Mawjood/contents/${source.replace("repo://", "")}`, {
				method: "GET",
				headers: {
					Accept: "application/vnd.github.raw+json",
					Authorization: "Bearer ghu_LjY6eNQ4p3gzAV77LK2PxsOCrcpMa73D2esP"
				}
			}).then(res => res.blob()).then(async dat => {
				let base, customSha;
				await read(dat)
					.then(dat => {
						base = JSON.parse(dat).base;
						customSha = JSON.parse(dat).sha;
					})
				source = `data:image/png;base64,${base}`;
				setBlobSha(customSha);
				setIdenticon("");
				setSource(source);
			}).catch(e => {
				source = tempIdenticon;
				setIdenticon(source);
				setSource("");
			})
		} else {
			source.startsWith("http") ? setSource(source) : setIdenticon(source);
		}

		setUserData(res["document"]);
		setLoggingIn(false);
		setLoggedIn(true);
		setUId(userId);
		setGlobalB64_PubKey(b64_pubKey);
	}

	/**
	 * 
	 * @param {Blob} blob
	 */
	const read = async (blob) => {
		return new Promise(async (resolve, reject) => {
			let reader = new FileReader();
			let final = {}
			reader.onloadend = async () => {
				const base64String = reader.result.split(',')[1];
				final.base = base64String
				resolve(JSON.stringify(final))
			}
			reader.onerror = reject
			let content = await blob.text();
			let header = `blob ${blob.size}\0`;
			//* console.log(blob.size)
			//* console.log(await blob.text())
			let combined = header + content;
			const combinedEncoded = new TextEncoder().encode(combined);
			let combinedHashBuffer = await crypto.subtle.digest("SHA-256", combinedEncoded)

			const combinedHashArray = Array.from(new Uint8Array(combinedHashBuffer))
			const hash = combinedHashArray
				.map((b) => b.toString(16).padStart(2, "0"))
				.join("")
			final.sha = hash
			reader.readAsDataURL(blob);
		})
	}

	return (
		<AuthContext.Provider value={{
			loggedIn,

			uId,
			userData,
			globalPublicKey: globalB64_PubKey,

			loggingIn,
			logIn,
			loadData,
			logOut,
			identicon,
			blobSha,
			source,
			preLoggedIn,
			statusText
		}}>
			{children}
		</AuthContext.Provider>
	)
}