import * as React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross } from "@fortawesome/free-solid-svg-icons/faCross";
import { faMars } from "@fortawesome/free-solid-svg-icons/faMars";
import { faVenus } from "@fortawesome/free-solid-svg-icons/faVenus";
import { faStarAndCrescent } from "@fortawesome/free-solid-svg-icons/faStarAndCrescent";
import { faStarOfDavid } from "@fortawesome/free-solid-svg-icons/faStarOfDavid";

import { AppThemeContext } from "../../contexts/colors";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from "@mui/material/Skeleton"

const StudentScreenStudent = React.memo(({ skeleton, name, englishName, gender, religion, extraMarginBottom=false, stdId, onCopyArabic=()=>{}, onCopyEnglish=()=>{}, onEdit=()=>{}, onDelete=()=>{} }) => {
	const { dark, colors } = React.useContext(AppThemeContext);
	const [contextMenu, setContextMenu] = React.useState(null);

	let studentItemStyles = React.useMemo(() => ({
		container: {
			display: "flex",
			backgroundColor: colors().listItem,
			justifyContent: "center",
			alignContent: "center",
			flexDirection: "row",
			padding: 10,
			marginBottom: extraMarginBottom ? 20 : 5,
			borderRadius: 15,
			width: "95%",
			marginLeft: "auto",
			marginRight: "auto",
			height: 90
		},
		iconContainer: {
			display: "flex",
			marginTop: "auto",
			marginBottom: "auto",
			flexDirection: "row"
		},
		stdName: {
			display: "flex",
			flexDirection: "column",
			color: colors().dynamicWhite,
			fontWeight: "bold",
			fontSize: 22,
			width: "71%",
			textAlign: "right",
			marginLeft: "auto",
			marginTop: "auto",
			marginBottom: "auto",
			padding: 2,
			userSelect: "none"
		},
		englishStdName: {
			color: colors().dynamicWhite + "88",
			fontWeight: "normal",
			fontSize: 17,
			textAlign: "left",
			width: "40%",
			padding: 4,
			display: '-webkit-box',
			WebkitBoxOrient: 'vertical',
			wordWrap: "break-word",
			WebkitLineClamp: 1,
			overflow: 'hidden',
			textOverflow: 'ellipsis', // Adds ellipsis at the end when text is truncated
		},
	}), [dark, extraMarginBottom]);

	const handleContextMenu = (event) => {
		event.preventDefault();
		setContextMenu(
			contextMenu === null
				? {
					mouseX: event.clientX + 2,
					mouseY: event.clientY - 6,
				}
				: // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
				// Other native context menus might behave different.
				// With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
				null,
		);
	};

	const handleClose = () => {
		setContextMenu(null);
	};

	React.useEffect(() => {
		const changeMenuStyles = async () => {
			await new Promise(resolve => setTimeout(resolve, 0)); // Allow layout to complete
			document.querySelectorAll(".MuiMenu-paper").forEach(ele => {
				ele.style.backgroundColor = colors().success;
			})
			document.querySelectorAll(".MuiMenuItem-root").forEach(ele => {
				ele.style.backgroundColor = colors().primary;
				ele.style.color = colors().constantWhite;
				ele.style.fontWeight = "bold";
				ele.style.fontFamily = "Ubuntu";
				ele.style.paddingRight = "35px";
			})
		}

		changeMenuStyles();
	}, [contextMenu])

	return (
		<>
			<div onContextMenu={handleContextMenu} style={studentItemStyles.container}>
				<div style={studentItemStyles.iconContainer}>
					{skeleton
						? <Skeleton variant="rounded" sx={{ bgcolor: "grey.800", width: 64, height: 64, borderRadius: 5 }} />
						: <FontAwesomeIcon
							icon={
								religion === "M"
									? faStarAndCrescent
									: religion === "C" ? faCross : faStarOfDavid
							}
							transform={{ size: 50 }}
							color={religion === "M"
								? "green"
								: religion === "C" ? (dark ? "#653294" : "#803ebd") : "cyan"
							}
							style={{ backgroundColor: dark ? colors().secondary : colors().primary, borderRadius: 22, padding: 25 }}
						/>}
					{skeleton
						? <Skeleton variant="rounded" sx={{ bgcolor: "grey.800", width: 64, height: 64, borderRadius: 5, marginLeft: 1 }} />
						: <FontAwesomeIcon
							icon={gender === "M" ? faMars : faVenus}
							transform={{ size: 50 }}
							color={gender === "M" ? "#0095ff" : "hotpink"}
							style={{ marginLeft: 10, backgroundColor: dark ? colors().secondary : colors().primary, borderRadius: 22, padding: 25 }}
						/>}
				</div>
				{skeleton
					? <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
						<Skeleton variant="text" sx={{ ...studentItemStyles.stdName, bgcolor: "grey.800", width: "30%", fontSize: 8 }} />
						<Skeleton variant="text" sx={{ ...studentItemStyles.stdName, bgcolor: "grey.800", width: "20%", fontSize: 0 }} />
					</div>
					: <h1 style={studentItemStyles.stdName}>{name}<br /><p style={{ ...studentItemStyles.stdName, ...studentItemStyles.englishStdName}}>{englishName}</p></h1>
				}
				<Menu
					open={contextMenu !== null}
					onClose={handleClose}
					anchorReference="anchorPosition"
					anchorPosition={
						contextMenu !== null
							? { top: contextMenu.mouseY, left: contextMenu.mouseX }
							: undefined
					}
				>
					<MenuItem onClick={() => { onCopyArabic(); handleClose(); }}>Copy Arabic Name</MenuItem>
					<MenuItem onClick={() => { onCopyEnglish(); handleClose(); }}>Copy English Name</MenuItem>
					<MenuItem onClick={() => { onEdit(); handleClose(); }}>Edit Student</MenuItem>
					<MenuItem onClick={() => { onDelete(stdId); handleClose(); }}>Delete Student</MenuItem>
				</Menu>
			</div>
		</>
	);
})

export default StudentScreenStudent;