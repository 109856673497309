import EXCEL_STRUCTURE from "../../Assets/Images/Excel_file.png";

import React, { useContext, useEffect, useRef, useState } from "react";

import { AppThemeContext } from "../../contexts/colors";

import UtilityOverlay from "../../Components/Utilities/UtilityOverlay";
import { Unstable_NumberInput as BaseNumberInput, numberInputClasses } from '@mui/base/Unstable_NumberInput';
import SpreadsheetUpload from "../../Components/Functions/SpreadsheetUpload";

const cyan = {
	50: '#E9F8FC',
	100: '#BDEBF4',
	200: '#99D8E5',
	300: '#66BACC',
	400: '#1F94AD',
	500: '#0D5463',
	600: '#094855',
	700: '#063C47',
	800: '#043039',
	900: '#022127',
};

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
};

function Styles({ darkMode=false, colorsArray }) {
	return (
		<style>
			{`
      .CustomNumberInput {
        font-family: 'IBM Plex Sans', sans-serif;
        font-weight: 400;
        border-radius: 8px;
        color: ${darkMode ? grey[300] : grey[900]};
        background: ${colorsArray.gray};
        border: 1px solid ${darkMode ? grey[700] : grey[200]};
        box-shadow: 0px 2px 4px ${darkMode ? colorsArray.lightButton : colorsArray.listItem};
		width: 50%;
		margin-left: auto;
		margin-right: auto;	
        display: grid;
        grid-template-columns: 1fr 19px;
        grid-template-rows: 1fr 1fr;
        column-gap: 8px;
        padding: 4px;
      }

      /*.CustomNumberInput:hover {
        border-color: ${colorsArray.success};
      }*/	

      .CustomNumberInput.${numberInputClasses.focused} {
        border-color: ${colorsArray.mawjoodMain};
        box-shadow: 0 0 0 3px ${colorsArray.success + "88"};
      }

      .CustomNumberInput .input {
        font-size: 0.875rem;
        font-family: inherit;
        font-weight: 400;
        line-height: 1.5;
        grid-column: 1/2;
        grid-row: 1/3;
        color: white;
		font-weight: bold;
        background: inherit;
        border: none;
        border-radius: inherit;
        padding: 8px 12px;
		width: 80%;
        outline: 0;
      }

	  .CustomNumberInput .input::placeholder {
			color: ${darkMode ? colorsArray.lightButton : colorsArray.listItem};
	  }

      .CustomNumberInput .input:focus-visible {
        outline: 0;
      }

      .CustomNumberInput .btn {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        appearance: none;
        padding: 0;
        width: 19px;
        height: 19px;
        font-family: system-ui, sans-serif;
        font-size: 0.875rem;
        line-height: 1;
        box-sizing: border-box;
        background: ${darkMode ? grey[900] : '#fff'};
        border: 0;
        color: ${darkMode ? grey[300] : grey[900]};
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 120ms;
      }

      .CustomNumberInput .btn:hover {
        background: ${darkMode ? grey[800] : grey[50]};
        border-color: ${darkMode ? grey[600] : grey[300]};
        cursor: pointer;
      }

      .CustomNumberInput .btn.increment {
        grid-column: 2/3;
        grid-row: 1/2;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border: 1px solid;
        border-bottom: 0;
        border-color: ${darkMode ? colorsArray.mawjoodMain : colorsArray.mawjoodOther};
        background: ${darkMode ? colorsArray.mawjoodOther : colorsArray.mawjoodMain};
        color: ${darkMode ? colorsArray.mawjoodMain : colorsArray.mawjoodOther};

          &:hover {
            cursor: pointer;
            color: ${darkMode ? colorsArray.mawjoodOther : colorsArray.mawjoodMain};
            background: ${darkMode ? colorsArray.mawjoodMain : colorsArray.mawjoodOther};
            border-color: ${darkMode ? cyan[400] : cyan[600]};
          }
      }

      .CustomNumberInput .btn.decrement {
        grid-column: 2/3;
        grid-row: 2/3;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border: 1px solid;
        border-color: ${darkMode ? colorsArray.mawjoodMain : colorsArray.mawjoodOther};
        background: ${darkMode ? colorsArray.mawjoodOther : colorsArray.mawjoodMain};
        color: ${darkMode ? colorsArray.mawjoodMain : colorsArray.mawjoodOther};

          &:hover {
            cursor: pointer;
            color: ${darkMode ? colorsArray.mawjoodOther : colorsArray.mawjoodMain};
            background: ${darkMode ? colorsArray.mawjoodMain : colorsArray.mawjoodOther};
            border-color: ${darkMode ? cyan[400] : cyan[600]};
          }
        }

      & .arrow {
        transform: translateY(-1px);
      }
      `}
		</style>
	);
}

export default function UploadClassSpreadsheet({ visible, setVisible, onSubmit=(value) => {} }) {
	const {
		dark,
		colors
	} = useContext(AppThemeContext);
	const [ firstFemale, setFirstFemale ] = useState(null);
	const [ classValue, setClassValue ] = useState(null);
	const inputRef = useRef(null);

	useEffect(() => {
		const changeTextfieldStyles = async () => {
			await new Promise(resolve => setTimeout(resolve, 0)); // Allow layout to complete

			const normalColor = dark ? colors().lightButton : colors().listItem;
			const focusedColor = colors().success;

			const insertRule = (selector, styles) => {
				const style = document.styleSheets[0];
				const rule = `${selector} { ${styles} }`;
				style.insertRule(rule, style.cssRules.length);
			};

			insertRule('.edit-mawjood-student div:before', `border-bottom: 2px solid ${normalColor} !important;`);
			insertRule('.edit-mawjood-student div:after', `border-bottom: 2px solid ${focusedColor} !important;`);

			insertRule('.edit-mawjood-student div.Mui-error:before', `border-bottom: 2px solid ${colors().danger} !important;`);
			insertRule('.edit-mawjood-student div.Mui-error:after', `border-bottom: 2px solid ${colors().danger} !important;`);

			insertRule('.edit-mawjood-student div', `background-color: ${colors().gray} !important;`);
			insertRule('.edit-mawjood-student p', `font-weight: bold; font-family: monospace; letter-spacing: unset; font-size: 15px; color: ${colors().danger} !important;`);

			insertRule('.edit-mawjood-student label', `color: ${normalColor} !important; font-weight: bold`);
			insertRule('.edit-mawjood-student label.Mui-focused', `color: ${focusedColor} !important;`);
			insertRule('.edit-mawjood-student label.Mui-error', `color: ${colors().danger} !important;`);

			insertRule('#arabic-name, #english-name', `color: white !important; font-weight: bold`);
			insertRule('#arabic-name', `text-align: right; direction: rtl !important;`);
		}

		changeTextfieldStyles();
	}, [dark]);

	return (
		<UtilityOverlay
			modalVisible={visible}
			setModalVisible={setVisible}
			modalTitle="Edit Student"
			CTA="Continue"
			CTAResult={() => { onSubmit(classValue); setFirstFemale(null); setClassValue(null); }}
			CTADisabled={!firstFemale || !classValue}
			useChildren
			contentHeight={420}
			scrollContent
		>
			<h2 style={{ textAlign: "center", fontWeight: "bold", color: "white", fontFamily: "Ubuntu Mono" }}>Use this screen to upload a spreadsheet to populate the class:</h2>

			<h3 style={{ fontWeight: "bold", color: "white", fontFamily: "Ubuntu Mono" }}>Make sure your .xlsx file looks like this:</h3>
			<img src={EXCEL_STRUCTURE} alt="Excel Structure" style={{ width: "550px", marginLeft: "auto", marginRight: "auto" }} />

			<h3 style={{ fontWeight: "bold", color: "white", fontFamily: "Ubuntu Mono" }}><br />What number is the first female student in your spreadsheet?</h3>
			<BaseNumberInput
				slotProps={{
					root: { className: 'CustomNumberInput' },
					input: { className: 'input', ref: inputRef },
					decrementButton: { className: 'btn decrement', children: '▾' },
					incrementButton: { className: 'btn increment', children: '▴' },
				}}
				aria-label="Demo number input"
				placeholder="Type a number…"
				min={1}
				max={100}
				onChange={(e) => setFirstFemale(inputRef.current.value) }
			/>

			<h3 style={{ fontWeight: "bold", color: "white", fontFamily: "Ubuntu Mono" }}><br />Upload your spreadsheet:</h3>
			<SpreadsheetUpload disabled={!firstFemale} firstFemale={firstFemale} onUpload={(clValue) => setClassValue(clValue)} />
			<Styles darkMode={dark} colorsArray={colors()} />
		</UtilityOverlay>
	)
}