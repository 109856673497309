import "./WelcomeScreenModal.css";

import * as React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppThemeContext } from "../../contexts/colors";
import { localeContext } from "../../contexts/localeManagement";
import TouchableOpacity from "../Custom/TouchableOpacity";

import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark";

export default function UtilityOverlay({ modalVisible = true, setModalVisible = () => { }, modalTitle, colorPrimary = "", colorSecondary = "", CTA = "", modalContent, CTAResult = () => { }, CTADisabled=false, xVisible = true, fontSize, contentHeight, translateContent = true, useChildren = false, children, scrollContent = false }) {
	const {
		dark,
		colors
	} = React.useContext(AppThemeContext);
	const { locale, appText } = React.useContext(localeContext);

	let modalStyles = React.useMemo(() => ({
		dropdownBtnIcon: {
			display: "flex",
			flexDirection: "column",
			alignSelf: "flex-end",
			marginTop: -22
		},
		modalContainer: {
			display: "flex",
			flexDirection: "column",
			flex: 1,
			height: "100%",
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: colors().constantBlack + "55"
		},
		modalContent: {
			display: "flex",
			flexDirection: "column",
			backgroundColor: colorSecondary ? colorSecondary : dark ? colors().screen : colors().primary,
			padding: 20,
			[scrollContent ? "" : "justifyContent"]: 'center',
			shadowColor: '#000',
			shadowOffset: {
				width: 0,
				height: 0,
			},
			shadowOpacity: 0.25,
			shadowRadius: 4,
			elevation: 5,
			height: contentHeight || 350 - 77 - 44,
			overflowY: scrollContent ? "auto" : "hidden",
			width: "50%"
		},
		modalText: {
			display: "flex",
			flexDirection: "column",
			color: colors().constantWhite,
			fontWeight: "bold",
			fontSize: fontSize ? fontSize : 30,
			fontFamily: "Courier New",
			textAlign: "center"
		},
		modalHeader: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			backgroundColor: colorPrimary ? colorPrimary : dark ? colors().gray : colors().secondary,
			padding: 20,
			width: "50%",
			borderTopLeftRadius: 20,
			borderTopRightRadius: 20
		},
		modalFooter: {
			display: "flex",
			flexDirection: "column",
			backgroundColor: colorPrimary ? colorPrimary : dark ? colors().gray : colors().secondary,
			border: "none",
			outline: "none",
			padding: 20,
			paddingTop: 10,
			paddingBottom: 10,
			width: "50%",
			borderBottomLeftRadius: 20,
			borderBottomRightRadius: 20
		},
		modal_HF_Text: {
			display: "flex",
			flexDirection: "column",
			color: colors().constantWhite,
			fontFamily: locale === "ar" ? "ArabicMainNormal" : "",
			fontWeight: locale === "ar"
				? 400
				: "bold",
			textAlign: "center",
			marginLeft: "auto",
			marginRight: "auto",
			fontSize: 30,
		}
	}), [dark, colorPrimary, colorSecondary, fontSize, locale, contentHeight, scrollContent]);

	const toggleModal = () => {
		setModalVisible(!modalVisible);
	}

	const cancel = () => {
		setModalVisible(!modalVisible);
	}

	return (
		<>
			<Modal
				open={modalVisible}
				onClose={() => {
					cancel();
				}}
			>
				<Fade in={modalVisible}>
					<div style={modalStyles.modalContainer}>
						<div style={modalStyles.modalHeader}>
							<h1 style={modalStyles.modal_HF_Text}>{appText[modalTitle] || modalTitle}</h1>
							{xVisible ? <button style={{ border: "none", outline: "none", backgroundColor: "transparent", cursor: "pointer" }}>
								<FontAwesomeIcon icon={faXmark} size="5x" color={colors().constantWhite} style={{ ...modalStyles.dropdownBtnIcon, marginTop: 5 }}
									onClick={() => {
										cancel()
									}}
								/>
							</button> : null}
						</div>
						<div style={modalStyles.modalContent}>
							{useChildren
								? children
								: <h1 style={modalStyles.modalText}>{translateContent ? appText[modalContent] || modalContent : modalContent}</h1>
							}
						</div>
						<TouchableOpacity className="modalFooter" style={modalStyles.modalFooter}
							onPress={() => { CTAResult(); toggleModal(); }}
							disabled={CTADisabled}
							activeOpacity={0.7}
						>
							<h1 style={{ ...modalStyles.modal_HF_Text, fontSize: 20 }}>{appText[CTA] || CTA}</h1>
						</TouchableOpacity>
					</div>
				</Fade>
			</Modal>
		</>
	);
}