import { useContext, useEffect } from 'react';
import './App.css';
import { AppThemeContext, AppThemeProvider } from './contexts/colors';
import { localeContext, LocaleProvider } from './contexts/localeManagement';
import showConsoleWarning from './utils/consoleWarning';
import { AuthContext, AuthProvider } from './contexts/accountManagement';
import { Routes, Route, useLocation } from 'react-router-dom';
import LoginScreen from './Screens/Auth/LoginScreen';
import SettingsScreen from './Screens/Settings/SettingsScreen';

import Homepage from './Screens/Static/Homepage';
import { ClassProvider } from './contexts/classManagement';
import ViewClass from './Screens/Classes/ViewClass';
// import UnderConstruction from './UnderConstruction';

const routeMap = {
	"/": "Mawjood | Home",
	"/auth/login": "Mawjood | Login"
}

function BodyNavigator() {
	const { colors } = useContext(AppThemeContext);
	const { getApplicationLocale } = useContext(localeContext);
	const {
		// loggedIn,
		// loggingIn,
		loadData
	} = useContext(AuthContext);
	const curLocation = useLocation();

	useEffect(() => {
		if (routeMap[curLocation.pathname]) {
			document.title = routeMap[curLocation.pathname];
		}
	}, [curLocation])

	useEffect(() => {
		getApplicationLocale();
		showConsoleWarning();
	}, [getApplicationLocale])

	useEffect(() => {
		if (localStorage.getItem("uId")) {
			loadData(localStorage.getItem("uId"));
		}
	}, [])

	return (

		<div className="App" style={{
			margin: 0,
			padding: 0,
			height: "100%",
			display: "flex",
			flexDirection: "column",
			flex: 1
		}}>
			<Routes>
				<Route path="/" element={<Homepage />} />
				<Route path="/auth/login" element={<LoginScreen />} />
				<Route path="/account/:accountName" element={<SettingsScreen />} />
				<Route path="/class/:className" element={<ViewClass />} />
			</Routes>
		</div>
	)
}

function App() {
  return (
	<AuthProvider>
		<ClassProvider>
			<AppThemeProvider>
				<LocaleProvider>
					<BodyNavigator />
				</LocaleProvider>
			</AppThemeProvider>
		</ClassProvider>
	</AuthProvider>
  );
}

export default App;
