import React, { useContext, useEffect, useState } from "react";

import { AppThemeContext } from "../../contexts/colors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import PuffLoader from "react-spinners/PuffLoader"

import { handleFiles } from "../../utils/fileHelpers";

export default function SpreadsheetUpload({ disabled=false, firstFemale=22, onUpload=(classValue) => {} }) {
	const {
		dark,
		colors
	} = useContext(AppThemeContext);

	const [selectedFileName, setSelectedFileName] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const dropZone = document.getElementById('drop-zone');
		const fileInput = document.getElementById('file-input');

		const dropZoneClick = () => fileInput.click();
		const dropZoneDragover = (e) => {
			e.preventDefault();
			dropZone.style.background = '#f2f2f2';
		};
		const dropZoneDragleave = () => dropZone.style.background = '';
		const dropZoneDrop = (e) => {
			e.preventDefault();
			handleFiles(e.dataTransfer.files, setSelectedFileName, setLoading, onUpload);
		};
		const fileInputChange = (e) => {
			e.stopPropagation();
			handleFiles(e.target.files, setSelectedFileName, firstFemale, setLoading, onUpload);
		};

		dropZone.addEventListener('click', dropZoneClick);
		dropZone.addEventListener('dragover', dropZoneDragover);
		dropZone.addEventListener('dragleave', dropZoneDragleave);
		dropZone.addEventListener('drop', dropZoneDrop);
		fileInput.addEventListener('change', fileInputChange);

		return () => {
			dropZone.removeEventListener('click', dropZoneClick);
			dropZone.removeEventListener('dragover', dropZoneDragover);
			dropZone.removeEventListener('dragleave', dropZoneDragleave);
			dropZone.removeEventListener('drop', dropZoneDrop);
			fileInput.removeEventListener('change', fileInputChange);
		};
	}, [onUpload]);

	return (
		<>
			<div id="drop-zone" className={disabled ? "drop-zone-disabled" : ""}>
				<div className="drop-zone-icon">
					<FontAwesomeIcon color={dark ? colors().mawjoodMain : colors().secondary} icon={faCloudArrowUp} />
				</div>
				{/* <p style={{ color: colors().mawjoodOther }}>Drag and drop a file or click to upload</p> */}
				<p style={{ color: colors().mawjoodOther }}>
					{selectedFileName ? `Selected file: ${selectedFileName}` : 'Drag and drop a file or click to upload'}
				</p>
				{loading && <PuffLoader />}
				<input type="file" id="file-input" accept=".xlsx, .xls, .csv" disabled={loading || disabled} />
				<div className="drop-zone-footer">
					<FontAwesomeIcon icon={faFileExcel} />
					&nbsp;&nbsp;
					<span>Supported files: .xlsx, .xls, .csv</span>
				</div>
			</div>
			<style>
				{`
				#drop-zone {
					width: 300px;
					height: 200px;
					border: 1px solid ${colors().success};
					border-radius: 5px;
					padding: 20px;
					text-align: center;
					font-size: 16px;
					cursor: pointer;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					margin-right: auto;
					margin-left: auto;
				}

				#drop-zone.drop-zone-disabled {
					cursor: not-allowed;
					opacity: 0.5;
				}

				.drop-zone-icon {
					font-size: 48px;
				}

				.drop-zone-footer {
					font-size: 14px;
					color: #777;
					margin-top: 20px;
				}

				#file-input {
					display: none;
				}
			`}
			</style>
		</>
	);
}