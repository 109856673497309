import * as React from "react"
import { AppThemeContext } from "../../contexts/colors";
import { localeContext } from "../../contexts/localeManagement";
import {
	FontAwesomeIcon
} from "@fortawesome/react-fontawesome";
import { faStarAndCrescent } from "@fortawesome/free-solid-svg-icons/faStarAndCrescent";
import { faCross } from "@fortawesome/free-solid-svg-icons";
import { faMars } from "@fortawesome/free-solid-svg-icons/faMars";
import { faVenus } from "@fortawesome/free-solid-svg-icons/faVenus";
import TouchableOpacity from "../Custom/TouchableOpacity";

/**
 * The component for a major selection item
 */
export default function MajorSelection({ content, onPress, secondaryText = "", lastItem = false, width, isSwitch = false, changeLightColor=false, firstOptionData = { icon: "", color: "" }, secondOptionData = { icon: "", color: "" }, switchValue = null, setSwitchValue = (v = 0 | null) => { } }) {
	const {
		dark,
		colors
	} = React.useContext(AppThemeContext);
	const { locale, appText } = React.useContext(localeContext);
	const [selected, setSelected] = React.useState(switchValue || null);

	let majorSelectionStyles = React.useMemo(() => ({
		item: {
			display: "flex",
			backgroundColor: (changeLightColor && !dark) ? colors().secondary : colors().mawjoodMain,
			border: "none",
			outline: "none",
			width: width || "30%",
			marginLeft: "auto",
			marginRight: "auto",
			height: 80,
			borderRadius: isSwitch ? 40 : 30,
			marginBottom: lastItem ? 0 : isSwitch ? 10 : 20,
			flexDirection: isSwitch ? "row" : (locale === "ar" ? "row-reverse" : "row"),
			justifyContent: "space-between",
			padding: 15
		},
		itemText: {
			display: "flex",
			color: dark ? colors().mawjoodOther : colors().constantWhite,
			fontFamily: locale === "ar" ? "ArabicMainNormal" : "",
			fontWeight: locale === "ar"
				? 400
				: "bold",
			fontSize: 40,
			//[locale === "ar" ? "right" : "left"]: 20,
			marginBottom: "auto",
			marginTop: "auto",
			flexDirection: "column"
		},
		secondaryText: {
			display: "flex",
			color: dark ? colors().thumb : colors().mawjoodOther,
			fontFamily: "Courier New",
			fontWeight: "bold",
			fontSize: 25,
			//[locale === "ar" ? "right" : "left"]: 180,
			marginBottom: "auto",
			marginTop: "auto",
			flexDirection: "column"
		},
		icon: {
			display: "flex",
			backgroundColor: "transparent",
			border: "none",
			outline: "none",
			padding: 10,
			borderRadius: 100,
			[locale === "ar" ? "right" : "left"]: 10,
			width: 45,
			height: 80,
			justifyContent: "center",
			alignItems: "center",
			marginBottom: "auto",
			marginTop: "auto",
			flexDirection: "column"
		}
	}), [dark, locale, lastItem, isSwitch, width]);

	const firstIcon = React.useMemo(() => {
		let returnVal = faStarAndCrescent;
		switch (firstOptionData.icon) {
			case "mars":
				returnVal = faMars;
				break;

			default:
				break;
		}
		return returnVal;
	}, [firstOptionData.icon]);

	const secondIcon = React.useMemo(() => {
		let returnVal = faStarAndCrescent;
		switch (secondOptionData.icon) {
			case "cross":
				returnVal = faCross;
				break;

			case "venus":
				returnVal = faVenus;
				break;

			default:
				break;
		}
		return returnVal;
	}, [secondOptionData.icon]);

	return (
		<>
			{isSwitch
				? <div style={majorSelectionStyles.item}>
					<TouchableOpacity style={{ ...majorSelectionStyles.icon, display: "flex", justifyContent: "center", width: "50%", flexDirection: "column" }} onPress={() => { setSelected(1); setSwitchValue(1) }}>
						<FontAwesomeIcon
							icon={firstIcon}
							color={selected === 1 ? firstOptionData.color : colors().constantWhite}
							size="6x"
							style={{ display: "flex", alignContent: "center", alignItems: "center", alignSelf: "center", flexDirection: "column" }}
						/>
					</TouchableOpacity>
					<div style={{ display: "flex", width: 2, height: "100%", backgroundColor: "#fff", flexDirection: "column" }} />
					<TouchableOpacity style={{ ...majorSelectionStyles.icon, display: "flex", justifyContent: "center", width: "50%", flexDirection: "column" }} onPress={() => { setSelected(2); setSwitchValue(2) }}>
						<FontAwesomeIcon
							icon={secondIcon}
							color={selected === 2 ? secondOptionData.color : colors().constantWhite}
							size="6x"
							style={{ display: "flex", alignContent: "center", alignItems: "center", alignSelf: "center", flexDirection: "column" }}
						/>
					</TouchableOpacity>
				</div>
				: <TouchableOpacity style={majorSelectionStyles.item} onPress={onPress} activeOpacity={0.7}>
					<h1 style={majorSelectionStyles.itemText}>{appText[content] || content}</h1>
					{secondaryText ? <h1 style={majorSelectionStyles.secondaryText}>{appText[secondaryText] || secondaryText}</h1> : null}
				</TouchableOpacity>}
		</>
	);
}