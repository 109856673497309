import "./Card.css";
import { useContext, useMemo } from "react";
import { AppThemeContext } from "../../contexts/colors";
import { localeContext } from "../../contexts/localeManagement";

/**
 * 
 * @param {{ additionalStyles: import("react").CSSProperties }} param0 
 * @returns 
 */
export default function Card({ additionalStyles, title="Card Title", direction="ltr", content="Card Content", footer="Card Footer" }) {
	const {
		dark,
		colors
	} = useContext(AppThemeContext);
	const { locale, appText } = useContext(localeContext);

	return (
		<>
			<div className="app-card" style={{ backgroundColor: colors().primary, ...additionalStyles }}>
				<h3 style={{ direction }}>{title}</h3>
				<p style={{ direction }}>{content}</p>
				<h4>{footer}</h4>
			</div>
		</>
	);
}