// src/utils/fileHelpers.js
import * as XLSX from 'xlsx';


const genStudentId = () => {
	const nums = "1234567890";
	let finalId = "";
	for (let i = 0; i < 19; i++) {
		let rnum = Math.floor(Math.random() * nums.length);
		finalId += nums.substring(rnum, rnum + 1);
	}
	return finalId;
}

export const handleFiles = (files, setSelectedFileName, firstFemale, setLoading, callback) => {
	setLoading(true);
	const file = files[0];
	setSelectedFileName(file.name);
	const reader = new FileReader();
	reader.onload = (e) => {
		let data = e.target.result;
		const workbook = XLSX.read(data, { type: 'array' });
		const sheetName = workbook.SheetNames[0];
		const sheet = workbook.Sheets[sheetName];
		data = XLSX.utils.sheet_to_json(sheet);

		data.shift()
		let boysArray = data.filter(student => parseInt(student["d1"]) < firstFemale);
		let girlsArray = data.filter(student => parseInt(student["d1"]) >= firstFemale);
		boysArray.sort((a, b) => a["d3"].trim().localeCompare(b["d3"].trim()));
		girlsArray.sort((a, b) => a["d3"].trim().localeCompare(b["d3"].trim()));
		data = [...boysArray, ...girlsArray]

		let classValue = {};
		for (let i = 0; i < data.length; i++) {
			const student = data[i];
			classValue[`Student ${i + 1}`] = {
				arabicName: student["d3"]?.trim(),
				studentId: genStudentId(),
				religion: student["d6"] === "مسلم" ? "M" : "C",
				gender: parseInt(student["d1"]) < firstFemale ? "M" : "F"
			}
		}
		callback(classValue);
		// console.log(classValue);
		setLoading(false);
	};
	reader.readAsArrayBuffer(file);
};