import {
	createContext,
	useState,
	useContext
} from "react";
import { AuthContext } from "./accountManagement";
import WebKit from "../utils/web-kit";

const getItemWithExpiry = (key) => {
	const itemStr = localStorage.getItem(key);

	// If the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}

	const item = JSON.parse(itemStr);
	const now = new Date();

	// If the current time is past the expiration time, remove the item
	if (now.getTime() > item.expiry) {
		localStorage.removeItem(key);
		return null;
	}

	return item.value;
};

const encodeHeaderValue = (value) => {
	return value
		.split("")
		.map((char) => {
			const code = char.charCodeAt(0);
			return code <= 255 ? char : `\\u${code.toString(16).padStart(4, "0")}`;
		})
		.join("");
}

/**
 * @typedef {{success: boolean, data?: object, callToAction: string, message: string}} ClassManagerResult
 * @type {ClassManagerResult}
 */
export const ClassManagerResult = {}

export const ClassContext = createContext();

export function ClassProvider({children}) {
	const [currentClassData, setCurrentClassData] = useState({});
	const [currentClassAttendance, setCurrentClassAttendance] = useState({});
	const [classManagerBusy, setClassManagerBusy] = useState(false);
	const [currentClassPeriod, setCurrentClassPeriod] = useState(1);

	const apiUrl = process.env.REACT_APP_API_URL;
	const apiKey = "mawjoodStorage-Mr8Y9rInYA9yHr0lFYGT";
	const apiWebhookUrl = "https://discord.com/api/webhooks/1241027026639654922/2Qf5CQMtybiJtbg20dXlpWkYTrzzxnQmB0Hb3NgWz2ezQJOjhmxb6u-vXqmVSLSu2UCT";

	//* Cross-Context Data
	const {
		userData,
		globalPublicKey,
		uId
	} = useContext(AuthContext)

	//^ CLASS FUNCTIONS
	/**
	 * 
	 * @param {`${year}-${class}`} className
	 */
	const getClass = async (className) => {
		setClassManagerBusy(true);
		let res = {};

		const B64_AESKey = getItemWithExpiry("userAESKey");

		const sessionToken = getItemWithExpiry("userSessionToken");
		const AES_CryptoKey = await crypto.subtle.importKey(
			'raw',
			Buffer.from(B64_AESKey, "base64"), {
				name: "AES-CBC",
				length: 256
			},
			false,
			["encrypt", "decrypt"]
		);
		const encryptedSessionToken = await WebKit.encryptUsingAES_CBC(sessionToken, AES_CryptoKey);

		await fetch(`${apiUrl}/classes/get`, {
			method: "GET",
			headers: {
				apiKey,
				"className": className,

				id: uId,
				pubKey: globalPublicKey,
				"Authorization": `Bearer ${encryptedSessionToken.ciphertext}`,
				iv: encryptedSessionToken.iv
			}
		})
			.then(r => {
				if (r.ok) {
					return r.json();
				} else {
					return r.text();
				}
			})
			.then(dat => res = dat).catch(e => res = e);

		if (!res["document"]) {
			setCurrentClassData({});
			setClassManagerBusy(false);

			if (typeof res === "string") {
				return {
					success: false,
					message: res,
					callToAction: "Go Back"
				}
			}

			return {
				success: false,
				message: typeof res === "string" ? res : "Class not found...",
				callToAction: "Create new class"
			}
		}

		setCurrentClassData(res["document"]);
		setClassManagerBusy(false);
		
		return {
			success: true,
			message: "Class found",
			data: res["document"]
		}
	}

	/**
	 * 
	 * @param {`${year}-${class}`} className
	 * @param {{[stdName: string]: { arabicName: string, studentId: string, religion: string, gender: string }}} value
	 */
	const createClass = async (className, value) => {
		setClassManagerBusy(true);
		let res;

		const B64_AESKey = getItemWithExpiry("userAESKey");

		const sessionToken = getItemWithExpiry("userSessionToken");
		const AES_CryptoKey = await crypto.subtle.importKey(
			'raw',
			Buffer.from(B64_AESKey, "base64"), {
				name: "AES-CBC",
				length: 256
			},
			false,
			["encrypt", "decrypt"]
		);
		const encryptedSessionToken = await WebKit.encryptUsingAES_CBC(sessionToken, AES_CryptoKey);

		await fetch(`${apiUrl}/classes/create`, {
			method: "POST",
			headers: {
				apiKey,
				"className": className,
				"classValue": encodeHeaderValue(JSON.stringify(value)),

				id: uId,
				pubKey: globalPublicKey,
				"Authorization": `Bearer ${encryptedSessionToken.ciphertext}`,
				iv: encryptedSessionToken.iv
			}
		})
			.then(r => {
				if (r.ok) {
					return r.json();
				} else {
					return r.text();
				}
			})
			.then(dat => res = dat).catch(e => res = e);

		if (!res["insertedId"]) {
			setClassManagerBusy(false);
			
			if (typeof res === "string") {
				return {
					success: false,
					message: res,
					callToAction: "Go Back"
				}
			}

			return {
				success: false,
				message: "An unexpected error occured.",
				callToAction: "Retry"
			}
		}

		setClassManagerBusy(false);
		
		return {
			success: true,
			message: "Class created",
			data: res
		}
	}

	/**
	 * 
	 * @param {`${year}-${class}`} className
	 * @param {{[stdName: string]: { arabicName: string, studentId: string, religion: string, gender: string }}} value
	 */
	const editClass = async (className, value) => {
		//* console.log(className, value)
		setClassManagerBusy(true);
		let res;
		
		const B64_AESKey = getItemWithExpiry("userAESKey");

		const sessionToken = getItemWithExpiry("userSessionToken");
		const AES_CryptoKey = await crypto.subtle.importKey(
			'raw',
			Buffer.from(B64_AESKey, "base64"), {
				name: "AES-CBC",
				length: 256
			},
			false,
			["encrypt", "decrypt"]
		);
		const encryptedSessionToken = await WebKit.encryptUsingAES_CBC(sessionToken, AES_CryptoKey);

		await fetch(`${apiUrl}/classes/edit`, {
			method: "POST",
			headers: {
				apiKey,
				"className": className,
				"classValue": encodeHeaderValue(JSON.stringify(value)),

				id: uId,
				pubKey: globalPublicKey,
				"Authorization": `Bearer ${encryptedSessionToken.ciphertext}`,
				iv: encryptedSessionToken.iv
			}
		})
			.then(r => {
				if (r.ok) {
					return r.json();
				} else {
					return r.text();
				}
			})
			.then(dat => res = dat).catch(e => res = e);

		if (!res["modifiedCount"]) {
			setClassManagerBusy(false);
			console.error(res);
			
			if (typeof res === "string") {
				return {
					success: false,
					message: res,
					callToAction: "Go Back"
				}
			}
			
			return {
				success: false,
				message: "An unexpected error occured.",
				callToAction: "Retry"
			}
		}

		setClassManagerBusy(false);
		
		return {
			success: true,
			message: "Class edited",
			data: res
		}
	}
	
	//^ ATTENDANCE FUNCTIONS
	/**
	 * 
	 * @param {`${year}-${class}`} className 
	 * @param {`${dd}/${mm}/${yyyy}`} date 
	 * @param {number} period
	 * @param {string[]} data 
	 */
	const submitClassAttendance = async (className, date, period, data) => {
		setClassManagerBusy(true);
		let res = {};

		const B64_AESKey = getItemWithExpiry("userAESKey");

		const sessionToken = getItemWithExpiry("userSessionToken");
		const AES_CryptoKey = await crypto.subtle.importKey(
			'raw',
			Buffer.from(B64_AESKey, "base64"), {
				name: "AES-CBC",
				length: 256
			},
			false,
			["encrypt", "decrypt"]
		);
		const encryptedSessionToken = await WebKit.encryptUsingAES_CBC(sessionToken, AES_CryptoKey);

		await fetch(`${apiUrl}/attendance/create`, {
			method: "POST",
			headers: {
				apiKey,
				"className": className,
				"date": date,
				"period": period.toString(),
				"data": JSON.stringify(data),
				"classSize": Object.keys(currentClassData["value"]).length,

				id: uId,
				pubKey: globalPublicKey,
				"Authorization": `Bearer ${encryptedSessionToken.ciphertext}`,
				iv: encryptedSessionToken.iv
			}
		})
			.then(r => {
				if (r.ok) {
					return r.json();
				} else {
					return r.text();
				}
			})
			.then(dat => res = dat).catch(e => res = e);

		if (!res["modifiedCount"] && !res["insertedId"]) {
			setClassManagerBusy(false);

			return {
				success: false,
				message: "An unexpected error occurred.",
				callToAction: "Retry"
			}
		}
		setClassManagerBusy(false);

		return {
			success: true,
			message: `${res["modifiedCount"] ? "Updated" : "Submitted"} new attendance.`,
			data: res
		}
	}

	/**
	 * 
	 * @param {`${year}-${class}`} className
	 * @param {`${dd}/${mm}/${yyyy}`} date 
	 */
	const getClassAttendance = async (className, date) => {
		setClassManagerBusy(true);
		let res = {};

		const B64_AESKey = getItemWithExpiry("userAESKey");

		const sessionToken = getItemWithExpiry("userSessionToken");
		const AES_CryptoKey = await crypto.subtle.importKey(
			'raw',
			Buffer.from(B64_AESKey, "base64"), {
				name: "AES-CBC",
				length: 256
			},
			false,
			["encrypt", "decrypt"]
		);
		const encryptedSessionToken = await WebKit.encryptUsingAES_CBC(sessionToken, AES_CryptoKey);

		await fetch(`${apiUrl}/attendance/get`, {
			method: "GET",
			headers: {
				apiKey,
				"className": className,
				date,

				id: uId,
				pubKey: globalPublicKey,
				"Authorization": `Bearer ${encryptedSessionToken.ciphertext}`,
				iv: encryptedSessionToken.iv
			}
		})
			.then(r => {
				if (r.ok) {
					return r.json();
				} else {
					return r.text();
				}
			})
			.then(dat => res = dat).catch(e => res = e);

		if (!res["document"]) {
			setCurrentClassAttendance({});
			setClassManagerBusy(false);
			//* console.log(date, new Date().toLocaleDateString("en-GB"))

			if (typeof res === "string") {
				return {
					success: false,
					message: res,
					callToAction: "Go Back"
				}
			}

			return {
				success: false,
				message: "Date not found..." + (
					(date === new Date().toLocaleDateString("en-GB"))
					? " You can add data for today!"
					: " Can't add data to past dates!"
				),
				callToAction: (date === new Date().toLocaleDateString("en-GB"))
					? "Add data to this date"
					: "Go Back"
			}
		}

		if (!res["document"][className.split("-")[0]]) {
			setCurrentClassAttendance({});
			setClassManagerBusy(false);
			return {
				success: false,
				message: "No data for this year was taken on this" + (date === new Date().toLocaleDateString("en-GB") ? "" : " past") + " date...",
				callToAction: date === new Date().toLocaleDateString("en-GB") ? "Take attendance for this year" : "Go Back"
			}
		}
		if (!res["document"][className.split("-")[0]][className.split("-")[1]]) {
			setCurrentClassAttendance({});
			setClassManagerBusy(false);
			return {
				success: false,
				message: "No data for this class was taken on this" + (date === new Date().toLocaleDateString("en-GB") ? "" : " past") + " date...",
				callToAction: date === new Date().toLocaleDateString("en-GB") ? "Take attendance for this class" : "Go Back"
			}
		}
		
		setCurrentClassAttendance(res["document"]);
		setClassManagerBusy(false);
		
		return {
			success: true,
			message: "Class data found on this date",
			data: res["document"]
		}
	}

	const genStudentId = () => {
		const nums = "1234567890";
		let finalId = "";
		for (let i = 0; i < 19; i++) {
			let rnum = Math.floor(Math.random() * nums.length);
			finalId += nums.substring(rnum, rnum + 1);
		}
		return finalId;
	}

	return (
		<ClassContext.Provider value={{
			classManagerBusy,
			genStudentId,
			
			currentClassData,
			currentClassPeriod,
			currentClassAttendance,
			setCurrentClassPeriod,

			getClass,
			createClass,
			editClass,

			getClassAttendance,
			submitClassAttendance
		}}>
			{children}
		</ClassContext.Provider>
	)
}